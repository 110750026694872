var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import { TransitionDuration } from '@atomic/obj.constants/constants';
export var fadeIn = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n"], ["\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n"])));
var fadeOut = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  from {\n    opacity: 1;\n  }\n\n  to {\n    opacity: 0;\n  }\n"], ["\n  from {\n    opacity: 1;\n  }\n\n  to {\n    opacity: 0;\n  }\n"])));
export var Fade = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid transparent;\n  animation: ", " ", " linear forwards;\n"], ["\n  border: 1px solid transparent;\n  animation: ", " ", " linear forwards;\n"])), function (props) { return (props.show ? fadeIn : fadeOut); }, TransitionDuration);
var templateObject_1, templateObject_2, templateObject_3;
