import * as React from 'react';
import { Breakpoint } from '@atomic/obj.constants';
export var useMatchMedia = function (breakPoint) {
    if (breakPoint === void 0) { breakPoint = 'md'; }
    var _a = React.useState(), matchBreakpoint = _a[0], setMatchBreakpoint = _a[1];
    React.useEffect(function () {
        var checkMatch = function () {
            var mediaQuery = window === null || window === void 0 ? void 0 : window.matchMedia("(min-width: ".concat(Breakpoint[breakPoint], "px)"));
            setMatchBreakpoint(!(mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.matches));
        };
        checkMatch();
        window.addEventListener('resize', checkMatch);
        return function () { return window.removeEventListener('resize', checkMatch); };
    }, [breakPoint]);
    return { matchBreakpoint: matchBreakpoint };
};
