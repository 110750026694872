var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { Service } from 'typedi';
var ApolloClientBuilderService = /** @class */ (function () {
    function ApolloClientBuilderService() {
    }
    ApolloClientBuilderService.prototype.build = function (url) {
        var link = createHttpLink({ uri: url + '/graphql' });
        var client = new ApolloClient({
            link: link,
            cache: new InMemoryCache(),
        });
        return client;
    };
    ApolloClientBuilderService = __decorate([
        Service()
    ], ApolloClientBuilderService);
    return ApolloClientBuilderService;
}());
export { ApolloClientBuilderService };
