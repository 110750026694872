import * as React from 'react';
import { faCog, faSync, faSpinner, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ActivityIndicatorStyled } from './activity-indicator.component.style';
export var ActivityIndicator = function (props) {
    var type = props.type, size = props.size, light = props.light;
    var activityIndicator;
    switch (type) {
        case 'circle-notch':
            activityIndicator = React.createElement(ActivityIndicatorStyled, { icon: faCircleNotch, size: size, light: light, spin: true });
            break;
        case 'cog':
            activityIndicator = React.createElement(ActivityIndicatorStyled, { icon: faCog, size: size, light: light, spin: true });
            break;
        case 'sync':
            activityIndicator = React.createElement(ActivityIndicatorStyled, { icon: faSync, size: size, light: light, spin: true });
            break;
        case 'spinner':
            activityIndicator = React.createElement(ActivityIndicatorStyled, { icon: faSpinner, size: size, light: light, spin: true });
            break;
    }
    return activityIndicator;
};
